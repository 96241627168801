import React from 'react';
import styled from '@emotion/styled';

import SocialAuthButton from 'components/SocialAuthButton';
import FacebookLogo from 'assets/svgs/facebook.svg';
import GoogleLogo from 'assets/svgs/google.svg';
import { FACEBOOK_AUTH_LINK, GOOGLE_AUTH_LINK } from 'utils/constants';

const GoogleButton = styled(SocialAuthButton)({
  marginTop: 12,
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
});

const FacebookButton = styled(SocialAuthButton)((props) => ({
  backgroundColor: props.theme.colors.facebookBlue,
  ':hover': {
    backgroundColor: props.theme.colors.facebookBlueHover,
    color: props.theme.colors.white,
  },
}));

const SocialSignIn: React.FC = () => (
  <>
    <FacebookButton
      href={FACEBOOK_AUTH_LINK}
      icon={<FacebookLogo />}
      text="Sign up with Facebook"
    />
    <GoogleButton href={GOOGLE_AUTH_LINK} icon={<GoogleLogo />} text="Sign up  with Google" />
  </>
);

export default SocialSignIn;
