import styled from '@emotion/styled';

import { BREAKPOINTS } from 'utils/constants';

export default styled.form((props) => ({
  backgroundColor: props.theme.colors.backgroundGray,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '48px 70px',
  width: '100%',
  maxWidth: 360,
  margin: '133px 20px 16px 20px',
  zIndex: 10,
  marginTop: 133,
  [BREAKPOINTS[500]]: {
    padding: '48px 20px',
    margin: '60px 20px 16px 20px',
    maxWidth: '100%',
    backgroundColor: 'unset',
  },
}));
