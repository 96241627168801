import React, { useState } from 'react';
import styled from '@emotion/styled';
import { AxiosError, AxiosResponse } from 'axios';

import NameIcon from 'assets/svgs/name.svg';
import EmailIcon from 'assets/svgs/email.svg';
import PasswordIcon from 'assets/svgs/password.svg';
import RegistrationFormComponent from 'components/RegistrationForm';
import SocialSignIn from 'features/SocialSignIn';
import Text from 'components/Text';
import Input from 'components/RegistrationInput';
import Button from 'components/Button';
import LegalText from 'components/LegalText';
import Separator from 'components/Separator';
import { getCookie, COOKIE_KEY } from 'utils/cookies';
import { API_URLS, callCoreApi, METHODS, CUSTOM_ERROR_CODES } from 'api';
import { isEmailValid, isPasswordValid } from 'utils/helpers';
import { ERROR_TEXTS } from 'utils/constants';
import { useUpdateUserInfo } from 'state/UserInfoStateProvider';
import { useUserAnswersContext, IQuestionAndAnswerApiObject } from 'state/UserAnswersStateProvider';

export interface IMarketingProps {
  google_cid: string;
  appsflyer_web_id: string;
  fbc: string;
  fbp: string;
  fbclid: string;
  gclid: string;
  tune_offer_id: string;
  tune_transaction_id: string;
  source: string;
  medium: string;
  campaign: string;
  impact_click_id: string;
}

export interface IRegistrationApiProps {
  email: string;
  password: string;
  first_name: string;
  platform: 'web';
}

export interface ILoginApiProps {
  username: string;
  password: string;
}

export interface ILoginApiResponse {
  access: string;
  refresh: string;
}

declare global {
  interface Window {
    ga: any;
  }
}

const Title = styled(Text)({
  fontSize: 20,
  lineHeight: '30px',
  fontWeight: 600,
  marginBottom: 4,
});

const Description = styled(Text)({
  fontSize: 13,
  fontWeight: 400,
  opacity: 0.7,
  marginBottom: 32,
  lineHeight: '20px',
});

const ButtonContainer = styled.div({
  maxWidth: 229,
  width: '100%',
  alignSelf: 'center',
  marginTop: 24,
});

const RegisterForm: React.FC = () => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const updateUserInfo = useUpdateUserInfo();
  const { getFormattedAnswers } = useUserAnswersContext();

  const onClick = () => {
    if (isLoading) {
      return;
    }

    setLoading(true);
    setEmailError('');
    setNameError('');
    setPasswordError('');

    let areValidationErrors = false;

    if (!isEmailValid(email)) {
      setEmailError(ERROR_TEXTS.VALID_EMAIL);
      areValidationErrors = true;
    }

    if (!name) {
      setNameError(ERROR_TEXTS.BLANK_FIELD);
      areValidationErrors = true;
    }

    if (!isPasswordValid(password)) {
      setPasswordError(ERROR_TEXTS.PASSWORD_VALIDATION_ERROR);
      areValidationErrors = true;
    }

    if (areValidationErrors) {
      setLoading(false);
      return;
    }

    const fbp = getCookie(COOKIE_KEY._FBP);
    const fbc = getCookie(COOKIE_KEY._FBC);
    const appsflyer_web_id = getCookie(COOKIE_KEY.AF_USER_ID);
    const fbclid = getCookie(COOKIE_KEY.FBCLID);
    const gclid = getCookie(COOKIE_KEY.GCLID);
    const google_cid = getCookie(COOKIE_KEY.YOURS_CID);
    const tune_offer_id = getCookie(COOKIE_KEY.TUNE_OFFER_ID);
    const tune_transaction_id = getCookie(COOKIE_KEY.TUNE_TRANSACTION_ID);
    const campaign = getCookie(COOKIE_KEY.UTM_CAMPAIGN);
    const source = getCookie(COOKIE_KEY.UTM_SOURCE);
    const medium = getCookie(COOKIE_KEY.UTM_MEDIUM);
    const impact_click_id = getCookie(COOKIE_KEY.IMPACT_CLICK_ID);

    callCoreApi<IRegistrationApiProps & IMarketingProps>({
      method: METHODS.POST,
      url: API_URLS.REGISTER,
      data: {
        email,
        password,
        first_name: name,
        google_cid,
        appsflyer_web_id,
        platform: 'web',
        fbc,
        fbp,
        fbclid,
        gclid,
        tune_offer_id,
        tune_transaction_id,
        campaign,
        source,
        medium,
        impact_click_id,
      },
    })
      .then(() => {
        callCoreApi<ILoginApiProps & IMarketingProps>({
          method: METHODS.POST,
          url: API_URLS.LOGIN,
          data: {
            username: email,
            password,
            google_cid,
            appsflyer_web_id,
            fbc,
            fbp,
            fbclid,
            gclid,
            tune_offer_id,
            tune_transaction_id,
            campaign,
            source,
            medium,
            impact_click_id,
          },
        }).then((response: AxiosResponse<ILoginApiResponse>) => {
          updateUserInfo({
            accessToken: response.data.access,
            refreshToken: response.data.refresh,
          });

          const data = getFormattedAnswers();

          if (!data.length) {
            setLoading(false);

            return;
          }

          callCoreApi<IQuestionAndAnswerApiObject[]>({
            method: METHODS.POST,
            url: API_URLS.USER_ANSWERS,
            accessToken: response.data.access,
            data,
          });
        });
      })
      .catch((error: AxiosError) => {
        setLoading(false);

        if (
          error.response?.data?.custom_status_code === CUSTOM_ERROR_CODES.STATUS_VALIDATION_ERROR
        ) {
          const passwordError = error.response?.data?.errors?.password?.[0] || '';
          const emailError = error.response?.data?.errors?.email?.[0] || '';
          const firstNameError = error.response?.data?.errors?.fist_name?.[0] || '';

          setNameError(firstNameError);
          setPasswordError(passwordError);
          setEmailError(emailError);
        }
      });
  };

  return (
    <RegistrationFormComponent>
      <Title align="center">Sign up to Yours App</Title>
      <Description align="center">Get your personalized mindfulness experience</Description>
      <SocialSignIn />
      <Separator text="OR" />
      <Input
        value={name}
        onChange={setName}
        placeholder="First name"
        error={nameError}
        icon={<NameIcon />}
      />
      <Input
        value={email}
        onChange={setEmail}
        placeholder="Email"
        autoCapitalize="none"
        error={emailError}
        icon={<EmailIcon />}
      />
      <Input
        value={password}
        onChange={setPassword}
        placeholder="Password"
        type="password"
        error={passwordError}
        icon={<PasswordIcon />}
      />
      <ButtonContainer>
        <Button onClick={onClick} text="Sign up" isLoading={isLoading} />
      </ButtonContainer>
      <LegalText />
    </RegistrationFormComponent>
  );
};

export default RegisterForm;
