import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import mainBackgroundImage from 'assets/pngs/main_background.jpg';
import mobileBackgroundImage from 'assets/pngs/mobile_background.jpg';
import PublicComponent from 'navigation/PublicComponent';
import RegisterForm from 'features/Register';
import Text from 'components/Text';
import ExternalLink from 'components/ExternalLink';
import SEO from 'components/SEO';
import FullScreenWrapper from 'components/FullScreenWrapper';
import Header from 'components/Header';
import { scrollToTop } from 'utils/helpers';
import { setCookie, COOKIE_KEY } from 'utils/cookies';
import { EXTERNAL_LINKS, ROUTES } from 'utils/constants';
import { handlePageView } from 'features/Tracking/trackingApiRequests';

const StyledWrapper = styled(FullScreenWrapper)({
  justifyContent: 'flex-start',
});

const SignInText = styled(Text)((props) => ({
  color: props.theme.colors.lightGray,
  marginRight: '5px',
}));

const StyledLink = styled(ExternalLink)((props) => ({
  color: props.theme.colors.orange,
  marginRight: '5px',
  textDecoration: 'none',
}));

const SignInContainer = styled.div({
  display: 'flex',
  textAlign: 'center',
  flexDirection: 'row',
  marginTop: '16px',
  marginBottom: '16px',
});

const Register: React.FC = () => {
  useEffect(() => {
    scrollToTop();
    handlePageView('/register');

    if (window.ga?.getAll && typeof window.ga?.getAll === 'function') {
      setCookie(COOKIE_KEY.YOURS_CID, window.ga?.getAll()?.[0]?.get('clientId'));
    }
  }, []);

  return (
    <PublicComponent>
      <SEO />
      <StyledWrapper
        backgroundImage={mainBackgroundImage}
        mobileBackgroundImage={mobileBackgroundImage}
      >
        <Header isVisible logoLink={ROUTES.REGISTER} />
        <RegisterForm />
        <SignInContainer>
          <SignInText>Do you have an account?</SignInText>
          <StyledLink href={EXTERNAL_LINKS.WEB_APP}>Sign in</StyledLink>
        </SignInContainer>
      </StyledWrapper>
    </PublicComponent>
  );
};

export default Register;
