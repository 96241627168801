import React from 'react';
import styled from '@emotion/styled';

import Text from 'components/Text';
import PasswordValid from 'assets/svgs/password_valid.svg';

interface IPasswordValidator {
  password: string;
  isVisible: boolean;
}

interface IStyledText {
  isValid: boolean;
}

const Container = styled.div({
  backgroundColor: 'rgba(255, 255, 255, 0.98)',
  padding: '10px 16px',
  position: 'absolute',
  right: 0,
  left: 0,
  bottom: 56,
  borderRadius: 4,
  '&:after': {
    top: '100%',
    left: '5%',
    border: 'solid transparent',
    content: '" "',
    height: 0,
    width: 0,
    position: 'absolute',
    pointerEvents: 'none',
    borderTopColor: 'rgba(255, 255, 255, 0.98)',
    borderWidth: 5,
    marginLeft: -5,
  },
});

const PasswordValidContainer = styled.div({
  backgroundColor: 'rgba(11, 174, 136, 0.18)',
  width: 15,
  height: 15,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 30,
  marginRight: 4,
});

const TextContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '2px 0',
});

const StyledText = styled(Text)<IStyledText>((props) => ({
  color: props.isValid ? props.theme.colors.green : props.theme.colors.placeholderGray,
}));

const Dot = styled.div((props) => ({
  color: props.theme.colors.placeholderGray,
  textAlign: 'center',
  width: 15,
  height: 15,
  marginRight: 4,
}));

const PasswordValidator: React.FC<IPasswordValidator> = ({ password, isVisible }) => {
  const passwordIsLong = password.length > 8;
  const passwordHasUppercase = password.toLocaleLowerCase() !== password;
  const passwordContainsNumber = /\d/.test(password);

  if (!isVisible) {
    return null;
  }

  return (
    <Container>
      <TextContainer>
        {passwordIsLong && (
          <PasswordValidContainer>
            <PasswordValid />
          </PasswordValidContainer>
        )}
        {!passwordIsLong && <Dot>&bull;</Dot>}
        <StyledText isValid={passwordIsLong} type="small">
          Password has to be 9 characters long
        </StyledText>
      </TextContainer>
      <TextContainer>
        {passwordHasUppercase && (
          <PasswordValidContainer>
            <PasswordValid />
          </PasswordValidContainer>
        )}
        {!passwordHasUppercase && <Dot>&bull;</Dot>}
        <StyledText isValid={passwordHasUppercase} type="small">
          Password must contain upper case
        </StyledText>
      </TextContainer>
      <TextContainer>
        {passwordContainsNumber && (
          <PasswordValidContainer>
            <PasswordValid />
          </PasswordValidContainer>
        )}
        {!passwordContainsNumber && <Dot>&bull;</Dot>}
        <StyledText isValid={passwordContainsNumber} type="small">
          Password must contain at least 1 digit
        </StyledText>
      </TextContainer>
    </Container>
  );
};

export default PasswordValidator;
