import React, { useState } from 'react';
import styled from '@emotion/styled';

import Text from 'components/Text';
import PasswordValidator from 'components/PasswordValidator';
import Visible from 'assets/svgs/password_visible.svg';
import Invisible from 'assets/svgs/password_invisible.svg';

type TInputType = 'password' | 'number' | 'email' | 'text';

interface IInput {
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  type?: TInputType;
  error?: string;
  min?: number;
  icon: JSX.Element;
  autoCapitalize?: 'none';
}

interface IErrorProp {
  error?: string;
}

interface IFocusedProps {
  isFocused: boolean;
}

type TInput = IInput & IErrorProp;

const Container = styled.div<IErrorProp & IFocusedProps>((props) => ({
  border: `1px solid ${
    props.error
      ? props.theme.colors.error
      : props.isFocused
      ? 'rgba(255, 255, 255, 0.6)'
      : 'rgba(255, 255, 255, 0.2)'
  }`,
  backgroundColor: props.isFocused ? 'rgba(255, 255, 255, 0.1)' : 'unset',
  borderRadius: 7,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}));

const Wrapper = styled.div({
  width: '100%',
  margin: '6px 0',
  position: 'relative',
});

const IconContainer = styled.div({
  marginLeft: 18,
  display: 'flex',
  alignItems: 'center',
});

const StyledInput = styled.input((props) => ({
  border: 'none',
  background: 'transparent',
  fontSize: '15px',
  margin: '12px 18px 12px 0',
  padding: 0,
  paddingLeft: 10,
  outline: 'none',
  display: 'flex',
  flex: 1,
  color: props.theme.colors.white,
  '::placeholder': {
    color: props.theme.colors.lightGray,
  },
}));

const Error = styled(Text)((props) => ({
  color: props.theme.colors.error,
  paddingTop: 4,
}));

const TogglePassword = styled.div({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'flex-end',
  padding: '12px 18px 12px 12px',
});

const Input: React.FC<TInput> = ({
  onChange,
  value,
  type,
  placeholder,
  error,
  min,
  autoCapitalize,
  icon,
  ...rest
}) => {
  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);

  const onInputFocus = () => {
    setInputFocused(true);
  };

  const onInputBlur = () => {
    setInputFocused(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const onPasswordToggleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setPasswordVisibility(!isPasswordVisible);
  };

  const isPassword = type === 'password';

  const inputType = isPassword && isPasswordVisible ? 'text' : type;

  return (
    <Wrapper {...rest}>
      <Container error={error} isFocused={isInputFocused}>
        <IconContainer>{icon}</IconContainer>
        <StyledInput
          onChange={handleChange}
          value={value}
          type={inputType}
          placeholder={placeholder}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          min={min}
          autoCapitalize={autoCapitalize}
        />
        {isPassword && (
          <TogglePassword onClick={onPasswordToggleClick}>
            {isPasswordVisible ? <Visible /> : <Invisible />}
          </TogglePassword>
        )}
      </Container>
      {!!error && <Error type="small">{error}</Error>}
      <PasswordValidator isVisible={isPassword && isInputFocused} password={value} />
    </Wrapper>
  );
};

export default Input;
