import React from 'react';
import styled from '@emotion/styled';

import Line from 'components/Line';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  margin: '18px 0 14px 0',
});

const StyledText = styled.p((props) => ({
  color: props.theme.colors.white,
  textAlign: 'center',
  flexGrow: 1,
  margin: 0,
  fontWeight: 400,
}));

interface ISeparator {
  text: string;
}

const Separator: React.FC<ISeparator> = ({ text, ...rest }) => (
  <Container {...rest}>
    <Line />
    <StyledText>{text}</StyledText>
    <Line />
  </Container>
);

export default Separator;
